<template>
  <v-app-bar
    color="background"
    flat
    height="96px"
  >
    <v-btn
      class="mx-0 elevation-0 background"
      fab
      color="white"
      small
      @click="toggleNavigation"
    >
      <v-icon> mdi-menu </v-icon>
    </v-btn>

    <v-spacer v-if="this.$vuetify.breakpoint.mobile" />

    <v-spacer
      class="d-flex justify-center align-center"
      v-if="!this.$vuetify.breakpoint.mobile"
    >
      <v-btn-toggle
        v-model="active"
        borderless
        class="elevation-1"
        rounded
        :color="activeItem.color"
      >
        <v-btn
          v-for="item in menu"
          :key="item.value"
          :value="item.value"
          :to="item.path"
          width="150px"
        >
          <v-icon
            left
            :color="item.color"
          >
            {{ item.icon }}
          </v-icon>
          <span :class="`${item.color}--text ${item.colorModifier}`">{{
            item.title
          }}</span>
        </v-btn>
      </v-btn-toggle>
    </v-spacer>
    <div>
      <v-menu
        offset-y
        nudge-bottom="10"
        transition="slide-y-transition"
        max-width="400px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            large
            rounded
            v-bind="attrs"
            v-on="on"
          >
            <span
              v-if="!$vuetify.breakpoint.mobile"
              class="pl-1 grey--text text--darken-3"
              >{{ myInfo.username || myInfo.name }}</span
            >
            <v-avatar
              class="mx-2"
              size="32"
            >
              <img :src="myInfo.avatarLink" />
            </v-avatar>
            <v-icon color="primary"> mdi-chevron-down </v-icon>
          </v-btn>
        </template>
        <div>
          <v-list
            subheader
            two-line
          >
            <v-list-item>
              <v-list-item-avatar size="50">
                <img :src="myInfo.avatarLink" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-html="myInfo.name"> </v-list-item-title>
                <v-list-item-subtitle>
                  {{ myInfo.phone || `@${myInfo.username}` }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-list dense>
            <v-subheader
              class="px-4"
              style="height: 24px"
              >Menu</v-subheader
            >
            <v-list-item @click="onClickAccount">
              <v-list-item-icon>
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('account') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onClickLogout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('logout') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import colors from 'vuetify/lib/util/colors';

export default {
  data: () => ({
    active: null,
    menu: [
      {
        title: 'Shipping',
        value: 'shipping',
        icon: 'mdi-truck-fast',
        color: 'blue',
        colorModifier: '',
        path: '/app/shipping',
      },
      {
        title: 'Port',
        value: 'port',
        icon: 'mdi-semantic-web',
        color: 'orange',
        colorModifier: 'darken-4',
        path: '/app/port',
      },
      {
        title: 'Logistics',
        value: 'logistics',
        icon: 'mdi-dots-hexagon',
        color: 'blue-grey',
      },
    ],
    activeItem: null,
  }),
  computed: {
    ...mapState('ui', ['drawer']),
    ...mapState('user', ['myInfo']),
    currentApp() {
      if (this.$route.matched[1].path === '/app/shipping') {
        return 'shipping';
      }
      if (this.$route.matched[1].path === '/app/port') {
        return 'port';
      }
      return null;
    },
  },
  created() {
    this.active = this.currentApp;
    this.activeItem = this.menu.find((item) => item.value === this.active);
  },
  watch: {
    active: {
      async handler(value) {
        if (value) {
          this.activeItem = this.menu.find((item) => item.value === value);
          // await new Promise(resolve => setTimeout(resolve, 300))
          if (this.activeItem.value === 'shipping') {
            this.$vuetify.theme.themes.light.primary = '#3973d4';
          }
          if (this.activeItem.value === 'port') {
            this.$vuetify.theme.themes.light.primary = '#ce4900';
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('ui', ['TOGGLE_DRAWER', 'TOGGLE_DIALOG_USER_SETTINGS']),
    ...mapActions('auth', ['logout']),
    toggleNavigation() {
      this.TOGGLE_DRAWER(!this.drawer);
    },
    async onClickAccount() {
      this.TOGGLE_DIALOG_USER_SETTINGS({
        status: true,
      });
    },
    async onClickLogout() {
      localStorage.removeItem('accessToken');
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
:deep {
  .v-list-item__title,
  .v-list-item__subtitle {
    overflow: initial;
    text-overflow: initial;
    white-space: initial;
  }
}
</style>
