<template>
  <v-app id="inspire">
    <AppNavigation />

    <v-main class="pa-0 background">
      <AppBar />
      <v-container
        class="py-4 mb-10"
        :class="{
          'px-6': !$vuetify.breakpoint.mobile,
          'px-1': $vuetify.breakpoint.mobile,
        }"
        fluid
      >
        <transition
          name="fade"
          mode="out-in"
        >
          <router-view />
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppNavigation from '@/components/layout/AppNavigation.vue';
import AppBar from '@/components/layout/AppBar.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  components: { AppNavigation, AppBar },
  data: () => ({
    cards: ['Today', 'Yesterday'],
    drawer: null,
    links: [
      ['mdi-inbox-arrow-down', 'Inbox'],
      ['mdi-send', 'Send'],
      ['mdi-delete', 'Trash'],
      ['mdi-alert-octagon', 'Spam'],
    ],
  }),
  computed: {
    ...mapState('auth', ['authStatus']),
    ...mapState('user', ['myInfo']),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.getMyInfo();
      this.SET_AUTH_STATUS(true);
      // if (!this.myInfo.name){
      //   this.$router.push("/signup/update-require-info")
      // }
    } catch (error) {
      console.log('not logged in', error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapMutations('auth', ['SET_AUTH_STATUS']),
    ...mapActions('user', ['getMyInfo']),
    ...mapActions('auth', ['login', 'loginWithGoogle']),
  },
};
</script>

<style lang="scss" scoped>
:deep {
}
</style>
